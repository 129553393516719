@import '../../scss/style.scss';

.not-found {
    padding-top: 30px;
    padding-bottom: 30px;
}

.not-found__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 56vh;
    text-align: center;
    margin: 0 auto;
    width: 70%;
    overflow: hidden;
}

.not-found__title {
    text-align: center;
    width: 100%;
    font-size: 6rem;
    color: $colorMain;
    animation: shake .6s ease-in-out infinite alternate;
}
.not-found__title:before {
    content: attr(data-t);
    position: absolute;
    left: 50%;
    transform: translate(-50%,.34em);
    height: .1em;
    line-height: .5em;
    width: 100%;
    animation: scan .5s ease-in-out 275ms infinite alternate,glitch-anim .3s ease-in-out infinite alternate;
    overflow: hidden;
    opacity: .7;
}
.not-found__title:after {
    content: attr(data-t);
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translate(-50%,.34em);
    height: .5em;
    line-height: .1em;
    width: 100%;
    animation: scan 665ms ease-in-out .59s infinite alternate,glitch-anim .3s ease-in-out infinite alternate;
    overflow: hidden;
    opacity: .8;
}

@keyframes glitch-anim {
    0% {
        clip: rect(32px,9999px,28px,0)
    }

    10% {
        clip: rect(13px,9999px,37px,0)
    }

    20% {
        clip: rect(45px,9999px,33px,0)
    }

    30% {
        clip: rect(31px,9999px,94px,0)
    }

    40% {
        clip: rect(88px,9999px,98px,0)
    }

    50% {
        clip: rect(9px,9999px,98px,0)
    }

    60% {
        clip: rect(37px,9999px,17px,0)
    }

    70% {
        clip: rect(77px,9999px,34px,0)
    }

    80% {
        clip: rect(55px,9999px,49px,0)
    }

    90% {
        clip: rect(10px,9999px,2px,0)
    }

    to {
        clip: rect(35px,9999px,53px,0)
    }
}
@keyframes scan {
    0%,20%,to {
        height: 0;
        transform: translate(-50%,.44em)
    }

    10%,15% {
        height: 1em;
        line-height: .2em;
        transform: translate(-55%,.09em)
    }
}
@keyframes shake {
    0% {
        transform: translate(-1px)
    }

    10% {
        transform: translate(2px,1px)
    }

    30% {
        transform: translate(-3px,2px)
    }

    35% {
        transform: translate(2px,-3px);
        filter: blur(4px)
    }

    45% {
        transform: translate(2px,2px) skewY(-8deg) scaleX(.96);
        filter: blur(0)
    }

    50% {
        transform: translate(-3px,1px)
    }
}


.not-found__txt {
    @include privateTitle;
    margin-bottom: 30px;
}

.not-found__text {
    @include textUnbounded-one;
    line-height: 24px;
    color: rgba(49, 70, 90, .6);
}

.not-found__link {
    font-weight: 600;
    color: $colorRed;

    &:hover,
    &:focus-visible,
    &:active {
        color: $colorBlack;
    }
}

@media (max-width: 1024px) {
    .not-found__container {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .not-found__container {
        width: 100%;
    }

    .not-found__text {
        font-size: 14px;
    }

    .not-found__txt {
        font-size: 20px;
    }

    .not-found__title {
        font-size: 5rem;
    }
}