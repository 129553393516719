@import '../scss/fonts.scss';
@import '../scss/variables.scss';

@mixin btn-reset {
    padding: 0;
    border: none;
    outline: none;
    font-family: $font-main;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent;
    cursor: pointer;
}

@mixin title {
    font-family: $font-main;
    font-weight: 600;
    font-size: 22px;
    color: $colorBlack;
}

@mixin privateTitle {
    font-family: $font-main;
    font-weight: 600;
    font-size: 26px;
    line-height: 26px;
    color: $colorBlack;
}

@mixin textUnbounded-one {
    font-family: $font-main;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

@mixin textUnbounded-two {
    font-family: $font-main;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

@mixin textSans{
    font-family: $font-two;
    font-weight: 400;
    font-size: 14px;
    color: $colorBlack;
}

@mixin textSansTd{
    font-family: $font-two;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: $colorBlack;
}

@mixin grey-linear {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient($colorMain, $colorWhite);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
  
      &:focus-visible::after,
      &:hover::after,
      &:active::after {
        opacity: 1;
      }
}

@mixin tab-grey-linear {
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;

    &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #31465A 0%, rgba(49, 70, 90, 0.30) 100%);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:focus-visible::after,
    &:hover::after,
    &:active::after {
        opacity: 1;
    }

    &:focus-visible,
    &:hover {
        box-shadow: 0px 4px 10px 0px rgba(12, 7, 50, 0.25);
        color: $colorWhite;
    }
}

@mixin red-linear {
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: background-color 0.3s ease-in-out;

    &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient($colorRed, rgba(175, 11, 15, 0.30));
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:focus-visible::after,
    &:hover::after,
    &:active::after {
        opacity: 1;
    }

    &:focus-visible,
    &:hover,
    &:active {
        background-color: transparent;
    }
}

@mixin red-linear-outline {
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color 0.3s ease-in-out,
    outline-color 0.3s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient($colorRed, rgba(175, 11, 15, 0.30));
      z-index: -1;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &:focus-visible::after,
    &:hover::after,
    &:active::after {
      opacity: 1;
    }

    &:focus-visible,
    &:hover,
    &:active {
        color: $colorWhite;
        outline-color: transparent;
    }
}

@mixin green-linear {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient($colorGreen, $colorWhite);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:focus-visible::after,
    &:hover::after,
    &:active::after {
        opacity: 1;
    }
}