@font-face {
    font-family: 'Unbounded';
    src: local('Unbounded-Regular'), local('unbounded-regular'),
      url('../fonts/Unbounded-Regular.woff2') format('woff2'),
      url('../fonts/Unbounded-Regular.woff') format('woff');
    // font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'PTsans';
    src: local('PTsans-Regular'), local('ptsans-regular'),
      url('../fonts/ptsans-regular.woff2') format('woff2'),
      url('../fonts/ptsans-regular.woff') format('woff');
    // font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: local('roboto'), local('Roboto'),
      url('../fonts/roboto-regular.woff2') format('woff2'),
      url('../fonts/roboto-regular.woff') format('woff');
    // font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
    
  $font-main: 'Unbounded';
  $font-two: 'PTsans';
  $font-roboto: 'Roboto';