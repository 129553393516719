@import '../scss/fonts';
@import '../scss/mixins';
@import '../scss/variables';

html {
    box-sizing: border-box;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    outline: none;
  }
  
  img {
    max-width: 100%;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin: 0;
  }
  
  ul {
    padding: 0;
    list-style: none;
  }
  
  body {
    margin: 0;
    min-width: 320px;
    font-family: $font-main, sans-serif;
    background-color: $colorWhite;
    font-size: 0;
  }
  
  .container {
    padding: 0 150px;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
  
    @media (max-width: 1400px) {
      padding: 0 100px;
    }
  
    @media (max-width: 1300px) {
      padding: 0 50px;
    }
  
    @media (max-width: 1048px) {
      padding: 0 40px;
    }
  
    @media (max-width: 992px) {
      padding: 0 24px;
    }
  
    @media (max-width: 576px){
      padding: 0 15px;
   }
  }