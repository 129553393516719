$colorMain: #31465A;
$colorBlack: #000;
$colorWhite: #fff;
$colorLightGray: #7c7c7c;
$colorFooterBackground: #dedfea;
$colorRed: #b10207;
$colorGreen: #078550;
$colorAzure: #15827E;
$colorOrange: #CD8530;

