@import '../../scss/style.scss';


.login__container {
    display: flex;
    // height: calc(100vh - 62px - 39px);
    height: calc(100vh - 62px);
}

.login-wrapp-right {
    height: 100%;
    width: 50%;
}

.login-wrapp-right img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.login-wrapp-left {
    position: relative;
    width: 50%;
    // justify-content: center;
    // margin-top: 15%;
    display: flex;
    align-items: center;
    padding-left: 6%;
}

.form-wrapp {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 368px;
}

.login__title {
    @include privateTitle;
    margin-bottom: 50px;
    font-weight: 700;
}

.forgot-text {
    @include textUnbounded-two;
    margin-bottom: 30px;
    color: rgba(49, 70, 90, 0.7);
}

.login-page__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.input-password__wrapper,
.login-page__input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-page__password-wrapper-registration {
    margin-bottom: 40px;
}

.input-password__wrapper {
    position: relative;
}

.input-password__btn {
    @include btn-reset;
    position: absolute;
    right: 12px;
    top: 45%;
}

.login-label {
    @include textUnbounded-two;
    margin-bottom: 8px;
    color: $colorMain;
}

.input-password__field,
.login-page__input {
    @include textUnbounded-two;
    margin-bottom: 20px;
    height: 40px;
    padding-left: 10px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: $colorFooterBackground;
    color: $colorBlack;
}

.input-password__field {
    margin-bottom: 8px;
}

.forgot-password-link {
    @include textSans;
    display: flex;
    margin-bottom: 40px;
    width: 100%;
    text-decoration: underline;
    color: $colorRed;
}

.login-page__btn {
    @include btn-reset;
    @include textUnbounded-two;
    height: 40px;
    margin-top: 40px;
    margin-bottom: 15px;
    background-color: $colorRed;
    border-radius: 25px;
    color: $colorWhite;


    &:focus-visible,
    &:hover,
    &:active {
        background: linear-gradient($colorRed, rgba(175, 11, 15, 0.30));
    }
}


.login__registration-wrapp {
    display: flex;
}

.login__registration-text {
    @include textSans;
    margin-right: 8px;
    color: $colorMain;
}

.login__registration-link {
    @include textSans;
    font-weight: 700;
    text-decoration-line: underline;
    color: $colorMain;
}

.login__check-wrapp {
    display: flex;
    margin-bottom: 22px;
}


.checkbox__wrapp {
    position: relative;
    display: flex;
    padding-left: 30px;
    margin-right: 20px;
}

.checkbox__label {
    // position: relative;
    display: flex;
    align-items: center;
    //   padding-right: 20px;
    color: $colorMain;
    cursor: pointer;
}

.checkbox__input {
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.check__box {
    position: absolute;
    margin-left: -5px;
    width: 12px;
    height: 12px;
    border: 2px solid $colorMain;
    border-radius: 50%;
}

.login__registration-text.checkbox__txt {
    margin-left: 12px;
}

.checkbox__input:checked+.check__box {
    background-image: url('../../img/check-circle.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 12px;
    height: 12px;
}

.auth-success,
.auth-bad {
    @include textUnbounded-one;
    position: absolute;
    top: 9%;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    font-size: 15px;
    line-height: 100%;

    @media (max-width: 992px) {
        font-size: 14px;
    }

    @media (max-width: 368px) {
        font-size: 12px;
    }
}

.auth-bad {
    color: $colorRed;
}

.auth-bad.err-auth-bad {
    // top: 7%;
    text-align: center;
}

.auth-bad.auth-bad-forgot {
    top: 13%;
}

.auth-success {
    color: $colorGreen;
}

.error-login {
    outline: 2px solid $colorRed;
}

.auth-bad svg,
.auth-success svg {
    margin-right: 10px;
    width: 30px;
    height: 30px;

    @media (max-width: 368px) {
        margin-right: 5px;
        width: 22px;
        height: 22px;
    }
}


.registration-section {
    & .toggle-wrapp {
        margin-bottom: 30px;
    }

    & .login-page__btn {
        margin-top: 0;
    }

    & .login-wrapp-left {
        margin-top: 9%;
    }

    & .login-wrapp-right img {
        min-height: 1450px;
    }
}

.login__container {

    @media (max-width: 992px) {
        padding: 0 24px;
        margin: 0 auto;
        width: 100%;
        //   min-height: 100vh;
    }

    @media (max-width: 935px) {
        // height: calc(100vh - 62px - 57px);
        height: calc(100vh - 62px);
    }

    @media (max-width: 860px) {
        // height: calc(100vh - 60px - 57px);
        height: calc(100vh - 60px);
    }

    @media (max-width: 576px) {
        padding: 0 15px;
    }

    @media (max-width: 420px) {
        // height: calc(100vh - 60px - 74px);
        height: calc(100vh - 60px);
    }
}

.modal-registration {
    margin: 0 auto;
    text-align: center;
    width: 70%;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.modal-registration__title {
    @include privateTitle;
    margin-bottom: 30px;
    color: $colorGreen;
}

.modal-registration__text {
    @include textSansTd;
    font-weight: 600;
    color: $colorMain;
}

.login-wrapp-left .modal {
    opacity: 1;
}

@media (max-width: 1024px) {
    // .login-wrapp-left {
    //     margin-top: 5%;
    // }

    .registration-section .login-wrapp-left {
        margin-top: 5%;
    }

    .login__title {
        font-size: 22px;
    }

    .auth-success,
    .auth-bad {
        top: 8%;
        font-size: 12px;
    }

    .login-label {
        font-size: 12px;
    }

    .input-password__field,
    .login-page__input,
    .login-page__btn {
        margin-bottom: 10px;
        height: 36px;
    }

    .forgot-password-link,
    .forgot-text {
        margin-bottom: 16px;
    }

    .login-page__btn {
        margin-top: 20px;
    }

    .login__title {
        margin-bottom: 28px;
    }

    .auth-bad svg,
    .auth-success svg {
        width: 24px;
        height: 24px;
    }


}

@media (max-width: 992px) {

    .login-wrapp-right {
        display: none;
    }

    .login-wrapp-left {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        padding-left: 0;
        // padding-bottom: 30px;
        width: 100%;
    }

    .registration-section .login-wrapp-left {
        align-items: baseline;
        margin-top: 150px;
    }

    .login__registration-text,
    .login__registration-link {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .registration-section .modal__content {
        padding: 50px 0;
    }

    // .login__container {
    //     height: 100%;
    // }

    .login-wrapp-left {
        padding: 25px 0;
    }

    .input-password__field, .login-page__input, .login-page__btn {
        height: 30px;
        font-size: 12px;
    }

    // .input-password__field, .login-page__input, .login-page__btn {
    //     font-size: 12px;
    // }

    .login-label {
        margin-bottom: 4px;
        font-size: 10px;
    }

    .login__title {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .auth-bad svg, .auth-success svg {
        width: 20px;
        height: 20px;
    }

    .forgot-text {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .login__check-wrapp {
        flex-direction: column;
        text-align: center;
    }

    .registration-section .login-wrapp-left {
        // align-items: center;
        margin-top: 40px;
    }

    .form-wrapp {
        width: 100%;
    }
}