@import '../../scss/style.scss';

.toggle-wrapp {
  display: flex;
  align-items: center;
}

.toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-left: 10px;
    width: 42px;
    height: 24px;
    border-radius: 20px;
    background-color: #078550;
    cursor: pointer;
  }

  .toggle__text {
    @include textSans;
    font-size: 14px;
    color: $colorMain;
  }

  .toggle__label {
    display: flex;
  }
  
  .toggle__label svg{
    align-self: center;
    width: 20px;
    height: 20px;
  }

  .toggle--active-text {
    color: $colorGreen;
  }
  
  
  .toggle--active  {
    // transform: translateX(48px);
    background-color: #078550;
    transition: background-color .3s ease-in-out;
  }

  .toggle--active svg {
    transform: translateX(21px);
    transition: transform .3s ease-in-out;
  }

  .toggle--inactive {
    transition: background-color .3s ease-in-out;
  }

  .toggle--inactive svg {
    transform: translateX(2px);
    transition: transform .3s ease-in-out;
  }